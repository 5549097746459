import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorteDiarioModel } from '../corte-diario.models';

@Component({
  selector: 'modal-eventos',
  templateUrl: './modal-eventos.component.html',
  styleUrls: ['./modal-eventos.component.css'],
})
export class ModalEventosComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      gestionSelected: CorteDiarioModel.GetGestionesAbiertasXRutas;
      usuarioid: string;
      listEventos: Array<any[]>;
      listEventosCerrados: Array<any[]>;
      listEventosAbiertos: Array<any[]>;
    }
  ) {}

  ngOnInit(): void {}
}
