<div mat-dialog-title class="rowFlexNoWrap">
  <div class="rowText">Factura</div>
  <button
    mat-button
    mat-dialog-close
    class="close-button"
    style="margin-left: auto"
  >
    <img
      src="../../../assets/icons/cerrar.png"
      alt="Cerrar"
      style="height: 30px; width: 30px"
    />
  </button>
</div>

<mat-dialog-content
  class="mat-typography"
  style="
    height: 92%;
    max-height: max-content !important;
    overflow: hidden !important;
  "
>
  <div class="factura-list-container">
    <ul class="factura-list">
      <li *ngFor="let factura of data.listFacturas" class="factura-item">
        {{ factura.NumeroFactura }}
      </li>
    </ul>
  </div>
</mat-dialog-content>

<style>
  .factura-list-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }

  .factura-list {
    list-style-type: none; /* Sin viñetas */
    padding: 0; /* Sin padding */
    margin: 0; /* Sin margen */
    text-align: center; /* Centra el texto */
  }

  .factura-item {
    padding: 8px 0; /* Espacio entre elementos */
    font-size: 16px; /* Tamaño del texto */
    border-bottom: 1px solid #ddd; /* Línea divisoria entre elementos */
  }

  .factura-item:last-child {
    border-bottom: none; /* Quita la línea divisoria del último elemento */
  }
</style>
