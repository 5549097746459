<div mat-dialog-title style="display: flex; align-items: center">
  <div style="font-size: 20px; font-weight: bold">Alertas</div>
  <button
    mat-button
    mat-dialog-close
    class="close-button"
    style="margin-left: auto"
  >
    <img
      src="../../../assets/icons/cerrar.png"
      alt="Cerrar"
      style="height: 30px; width: 30px"
    />
  </button>
</div>

<mat-dialog-content
  class="mat-typography"
  style="height: 91%; max-height: 91% !important"
>
  <!--************Grid***********-->
  <!--************Encabezado***********-->
  <div
    class="flexRowNoWrap EncabezadoGridR1"
    style="
      justify-content: space-between;
      flex-wrap: nowrap;
      min-height: 36px;
      min-width: 1590px;
    "
  >
    <div style="min-width: 130px" class="cellListP">
      <div class="cellListE1">
        <span>Fecha Efectiva</span>
      </div>
    </div>
    <div style="min-width: 130px" class="cellListP">
      <div class="cellListE1">
        <span>Alerta</span>
      </div>
    </div>
    <div style="min-width: 130px" class="cellListP">
      <div
        class="cellListE1"
        style="display: unset; width: 280px; min-width: 280px"
      >
        <span>Categoria</span>
      </div>
    </div>
    <div style="min-width: 130px" class="cellListP">
      <div class="cellListE1">
        <span>Monto</span>
      </div>
    </div>

    <div style="min-width: 130px" class="cellListP">
      <div class="cellListE1">
        <span>Nivel</span>
      </div>
    </div>

    <div style="min-width: 130px" class="cellListP">
      <div class="cellListE1">
        <span>Usuario</span>
      </div>
    </div>
  </div>

  <!--************Body grids***********-->
  <div
    style="height: 84%; min-width: 1590px; overflow-y: auto; overflow-x: hidden"
  >
    <div
      *ngFor="let alerta of data.listAlertas"
      style="display: flex; justify-content: space-between; min-height: 36px"
      class="flexRowNoWrap rowGrid"
    >
      <div style="min-width: 130px" class="cellListP">
        <div class="cellListE1">
          <span>{{ alerta.FechaEfectiva }}</span>
        </div>
      </div>
      <div style="min-width: 130px" class="cellListP">
        <div class="cellListE1">
          <span>{{ alerta.SistemaAlertaTipoDescripcion }}</span>
        </div>
      </div>
      <div style="min-width: 130px" class="cellListP">
        <div class="cellListE1">
          <span>{{ alerta.CategoriaDescripcion }}</span>
        </div>
      </div>
      <div style="min-width: 130px" class="cellListP">
        <div class="cellListE1">
          <span>{{ alerta.ValorImpacto }}</span>
        </div>
      </div>
      <div style="min-width: 130px" class="cellListP">
        <div class="cellListE1">
          <span>{{ alerta.NivelDescripcion }}</span>
        </div>
      </div>
      <div style="min-width: 130px" class="cellListP">
        <div class="cellListE1">
          <span>{{ alerta.UsuarioNombre }}</span>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
