import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorteDiarioModel } from '../corte-diario.models';

@Component({
  selector: 'modal-alertas',
  templateUrl: './modal-alertas.component.html',
  styleUrls: ['./modal-alertas.component.css'],
})
export class ModalAlertasComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      gestionSelected: CorteDiarioModel.GetGestionesAbiertasXRutas;
      usuarioid: string;
      listAlertas: Array<any>;
    }
  ) {}

  ngOnInit(): void {}
}
