import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CorteDiarioModel } from '../corte-diario.models';

@Component({
  selector: 'modal-notas',
  templateUrl: './modal-notas.component.html',
  styleUrls: ['./modal-notas.component.css'],
})
export class ModalNotasComponent implements OnInit {
  constructor(
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      gestionSelected: CorteDiarioModel.GetGestionesAbiertasXRutas;
      usuarioid: string;
      listNotas: Array<any[]>;
    }
  ) {}

  ngOnInit(): void {}
}
