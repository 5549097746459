import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { CorteDiarioComponent } from './corte-diario.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { GraphByAnalisisComponent } from './graph-by-analisis/graph-by-analisis.component';
import { ChartModule, HIGHCHARTS_MODULES } from 'angular-highcharts';
import * as more from 'highcharts/highcharts-more.src';
import * as nodatadisplay from 'highcharts/modules/no-data-to-display.src';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { ModalCrearPlantillaComponent } from './modal-crear-plantilla/modal-crear-plantilla.component';
import { ModalCambiarFronteraComponent } from './modal-cambiar-frontera/modal-cambiar-frontera.component';
import { MatSelectModule } from '@angular/material/select';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ModalGestionesComponent } from './modal-gestiones/modal-gestiones.component';
import { GraphGridSwitchComponent } from './graph-grid-switch/graph-grid-switch.component';
import { GridRutasComponent } from './grid-rutas/grid-rutas.component';
import { ModalComparacionComponent } from './modal-comparacion/modal-comparacion.component';
import { ModalGraphComparacionComponent } from './modal-graph-comparacion/modal-graph-comparacion.component';
import { ModalGraphByAnalisisComponent } from './modal-graph-by-analisis/modal-graph-by-analisis.component';
import { RoundDecimalPipe } from './Pipes/roundDecimal.pipe';
import { CommaDecimalPipe } from './Pipes/commaDecimal.pipe';
import { ModalDeleteTemplateComponent } from './modal-delete-template/modal-delete-template.component';
import { ModalBitacoraComponent } from './modal-bitacora/modal-bitacora.component';
import { ModalFacturaComponent } from './modal-factura/modal-factura.component';
import { ModalNotasComponent } from './modal-notas/modal-notas.component';
import { ModalAlertasComponent } from './modal-alertas/modal-alertas.component';
import { ModalEventosComponent } from './modal-eventos/modal-eventos.component';

const routes = RouterModule.forChild([
  { path: 'home/Index/:UsuarioId', component: CorteDiarioComponent },
]);

@NgModule({
  declarations: [
    GraphByAnalisisComponent,
    ModalCrearPlantillaComponent,
    ModalCambiarFronteraComponent,
    ModalGestionesComponent,
    GraphGridSwitchComponent,
    GridRutasComponent,
    ModalComparacionComponent,
    ModalGraphComparacionComponent,
    ModalGraphByAnalisisComponent,
    CommaDecimalPipe,
    RoundDecimalPipe,
    ModalDeleteTemplateComponent,
    ModalBitacoraComponent,
    ModalFacturaComponent,
    ModalNotasComponent,
    ModalAlertasComponent,
    ModalEventosComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    routes,
    ChartModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatSelectModule,
    NgSelectModule,
  ],
  providers: [
    { provide: HIGHCHARTS_MODULES, useFactory: () => [more, nodatadisplay] },
  ],
})
export class CorteDiarioModule {}
